import { QueryClient, QueryClientProvider } from 'react-query'
import "./App.css";
import CamList from "./components/CamList";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <CamList />
    </QueryClientProvider>
  );
}

export default App;
