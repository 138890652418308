import { Key, ReactElement, JSXElementConstructor, ReactNode, ReactPortal, useState } from 'react';
import { useRef } from 'react';
import { useQuery } from 'react-query'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import { Swiper as SwiperType } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import './styles.css';

export default function CamList(): JSX.Element {
    const [index, setIndex] = useState(0);
    const swiperRef = useRef<SwiperType>();

    const { isLoading, error, data } = useQuery('repoData', () =>
        fetch('https://chixxx.online/cams-api/').then(res =>
            res.json()
        )
    )

    if (isLoading) return <p>'Loading...'</p>

    if (error instanceof Error && error) {
        return <p>{'An error has occurred: ' + error.message}</p>;
    }

    const StyledFab = styled(Fab)({
        position: 'absolute',
        zIndex: 1,
        top: -30,
        left: 0,
        right: 0,
        margin: '0 auto',
    });

    const theme = createTheme({
        palette: {
            primary: {
                main: "#212121",
            },
            secondary: {
                main: "#ff4081",
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppBar position="fixed" color="primary" sx={{ top: 0, bottom: 'auto' }}>
                <Typography
                    variant="h6"
                    noWrap
                    sx={{
                        ml: 2,
                        mr: 2,
                        fontFamily: 'Anton',
                        // letterSpacing: '.3rem',
                        color: 'inherit',
                        textDecoration: 'none',
                    }}
                >
                    Chixxx Online
                </Typography>
                <Typography
                    noWrap
                    sx={{
                        ml: 2,
                        mr: 2,
                        fontFamily: 'Bubbler One',
                        // letterSpacing: '.3rem',
                        color: 'inherit',
                        textDecoration: 'none',
                    }}
                >
                    The hottest teen girls on Chaturbate
                </Typography>
            </AppBar>
            <div className='pageNumber'>
                {`${(index + 1)}/100`}
            </div>
            <Swiper
                loop={true}
                onBeforeInit={(swiper: SwiperType) => {
                    swiperRef.current = swiper;
                }}
                onRealIndexChange={(swiper: SwiperType) => setIndex(swiper.realIndex)}
                className="mySwiper"
            >
                {data.map((item: {
                    [x: string]: any; Username: Key | null | undefined; DisplayName: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined;
                }) =>
                    <SwiperSlide key={item.Username}>
                        <Card sx={{ maxWidth: 345 }}>
                            <CardHeader
                                title={`${item.Username}`}
                                subheader={`Age: ${item.Age}`}
                            />
                            <CardMedia
                                component="img"
                                image={`${item.image_url_360x270}`}
                                alt={`${item.Username}`}
                            />
                            <CardContent>
                                <Typography variant="body2" color="text.secondary">
                                    {item.DisplayName}
                                </Typography>
                            </CardContent>
                        </Card>
                    </SwiperSlide>
                )}
            </Swiper >
            <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        onClick={() => swiperRef.current?.slidePrev()}
                        aria-label="previous">
                        <ArrowBackIcon />
                    </IconButton>
                    <StyledFab
                        color="secondary"
                        href={data[index].ChatRoomUrl}
                        aria-label="open">
                        <OpenInBrowserIcon />
                    </StyledFab>
                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton
                        color="inherit"
                        onClick={() => swiperRef.current?.slideNext()}
                        aria-label="next">
                        <ArrowForwardIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
        </ThemeProvider>
    )
}
